





























































































































































































































































































































































































































































































































































































































.searchList {
  padding: 0 !important;
  .listLabel {
    margin-left: 10px;
  }
  .listInput {
    width: 90%;
  }
}
.feedback /deep/ .el-input__inner {
  height: 32px;
  line-height: 32px;
}
.el-form-item {
  margin-bottom: 0;
  span {
    color: #909399;
    font-size: 12px;
  }
}
// .feedback /deep/ .el-table--enable-row-transition .el-table__body td {
//   padding: 0;
// }
.feedback /deep/ .el-dialog__body {
  overflow: hidden;
  .block {
    width: 50%;
    position: relative;
    img {
      width: 90% !important;
      margin-bottom: 8px;
      margin-left: 1.5%;
    }
  }
}
.feedback /deep/ .el-textarea__inner {
  min-height: 7.5rem;
  resize: none;
}
